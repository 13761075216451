// Note this file should be loaded after the <xsl:call-template name="tdi-js"/>; default NOT loaded.

// tooltips.init(); // enables tooltip support for footnotes and termrefs
// Open lightbox
$("#open-lightbox").click(function(){
    $(".lightbox_wrapper").show();
    $("body").addClass("noscroll");    // Add the 'noscroll' class to body
});
// Close lightbox and stop video
$(".close_button, .lightbox_background").click(function(){
    $(".lightbox_wrapper").hide();
    $("body").removeClass("noscroll");
    for (var i = 0; i < players.length; i++) {
        if(players[i] != null) { 
            players[i].pause();  // pause the video
        }  
    }
});
// Close lightbox with 'esc' key and stop video
$(document).keyup(function(e) {
    if (e.key === "Escape") {
        $(".lightbox_wrapper").hide();
        $("body").removeClass("noscroll");
        for (var i = 0; i < players.length; i++) {
            if(players[i] != null) { 
                players[i].pause();  // pause the video
            }  
        }
    }
});
//Plyr
    var players = []; // Empty array for al video players
    document.addEventListener('DOMContentLoaded', function() {
        var playerElements = Array.prototype.slice.call(document.querySelectorAll('[data-plyr-embed-id]'));
        for (var i = 0; i < playerElements.length; i++) {
            players[i] = new Plyr(playerElements[i]); // Store each Plyr instance in the players array
        }
    });
// Hover Watch Video Hero
$(window).on('load resize', function() { // Run when the page is loaded and when the window is resized
    if ($(window).width() >= 992) { // Check if window width is greater than or equal to 992px
        $(".hero_video-play").hover(
            function() {
                $(".hero_video-text").css("opacity", "1");
            }, function() {
                $(".hero_video-text").css("opacity", "0");
            }
        );
    }
    else {
        $(".hero_video-text").css("opacity", "1"); // Always visible on smaller screens
    }
});



(function($){
    $(function(){
      if (!cookie('cookiebar')) {
        $('.cookie-layer').show();
  
        $('.cookie-layer button').on('click', function(){
          cookie('cookiebar', {data: true, 'max-age': 30 * 86400});
          $('.cookie-layer').hide();
        });
  
        $('.cookie-layer a').on('click', function(){
          cookie('cookiebar', {data: true, 'max-age': 30 * 86400});
        });
      }
  
      let privacyCookie = cookie('privacy-cookie');
  
      if (!privacyCookie || !privacyCookie.data || (typeof(privacyCookie.data.analytics) === 'undefined')) {
        privacyCookie = {data: {analytics: true}, v: 0};
        cookie('privacy-cookie', {data: privacyCookie, 'max-age': 180 * 86400});
      }
  
      if (privacyCookie.data.analytics) {
        $('input[name=analytics]').prop('checked', true);
      }
  
      $('input[name=analytics]').on('click', function(){
        privacyCookie.data.analytics = this.checked;
        cookie('privacy-cookie', {data: privacyCookie, 'max-age': 180 * 86400});
      });
    });
  
    $('.share__link--mail').each(function(){
      $(this).attr('href',
        [
          'mailto:',
          '?subject=', encodeURIComponent(document.title),
          '&body=', encodeURIComponent(location.href)
        ].join('')
      );
    });
  
    $('.share__link--twitter').each(function(){
      $(this).attr('href',
        [
          'http://twitter.com/share?url=', encodeURIComponent(location.href)
        ].join('')
      );
    });
  
    $('.share__link--facebook').each(function(){
      $(this).attr('href',
        [
          'https://www.facebook.com/sharer/sharer.php?u=', encodeURIComponent(location.href)
        ].join('')
      );
    });
  
    $('.share__link--linkedin').each(function(){
      $(this).attr('href',
        [
          'http://www.linkedin.com/shareArticle?url=', encodeURIComponent(location.href)
        ].join('')
      );
    });
  })(jQuery);

function cookie(key, value)
{
  if (typeof(value) !== 'undefined') {
    let data;
    let properties = {path: '/', domain: location.hostname};
    let c;

    if (typeof(value) === 'object') {
      data = (typeof(value.data) === 'object') ? JSON.stringify(value.data) : String(value.data);
      for (let p in value) {
        if (p !== 'data') properties[p] = value[p];
      }
    }
    else {
      data = String(value);
    }
    if (data === '') {
      properties.expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
    }
    c = `${key}=${data}`;
    for (let p in properties) {
      c += `; ${p}=${properties[p]}`;
    }
    return document.cookie = c;
  }
  else {
    let cookies = {};

    document.cookie.split(';').forEach(c => {
      let tuple = c.split('=');
      //let data = tuple[1].trim();
      let data = tuple[1];

      try {
        data = JSON.parse(data);
      }
      catch (e) {
        // Not JSON
      }
      cookies[tuple[0].trim()] = data;

    });

    return key ? cookies[key] : cookies;
  }
}